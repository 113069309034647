
time.icon {
  font-size: .6em /* change icon size */;
  display: block;
  position: relative;
  width: 4.2rem;
  height: 4.8rem;
  background-color: #2a2a2a;
  margin: 0 auto;
  border-radius: 0.3em;
  //box-shadow: 0 1px 0 #7d7d7d, 0 2px 0 #ccc, 0 3px 0 #7d7d7d, 0 4px 0 #ccc, 0 0 0 1px #7d7d7d
  box-shadow: 0 0 0 #bdbdbd, 0 0 4px 2px rgba(255,255,255,.6);
  overflow: hidden;

  * {
    display: block;
    width: 100%;
    font-size: 1.3em;
    font-weight: bold;
    font-style: normal;
    text-align: center; }

  strong {
    position: absolute;
    top: 0;
    padding: 0.2em 0;
    color: #fff;
    background-color: #8d371b; }
  //border-bottom: 1px dashed #8d371b
  //box-shadow: 0 2px 0 #fd9f1b

  em {
    position: absolute;
    bottom: 0;
    color: #cd5027;
    border-top: 1px solid #7d7d7d;
    font-size: 1.5em; }

  span {
    width: 100%;
    font-size: 2.4em;
    letter-spacing: -0.05em;
    padding-top: .95em;
    color: #a8a8a8; } }

