
.calendar {
  p {
    cursor: default; }

  .tile.is-child {
    box-sizing: border-box;

    &.selected {
      box-shadow: 0 0 2px 2px #3298dc; } }

  .event {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .delete {
      margin-top: 4px;
      display: none; }
    &:hover {
      .delete {
        display: block; } } } }
