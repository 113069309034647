
form {
  .control.has-icons-left, .control.has-icons-right {
    .icon {
      color: #333;
      height: 1.2em;
      pointer-events: none;
      position: absolute;
      top: .6em;
      width: 2.5em;
      z-index: 4; } } }
