@import '../../node_modules/bulma/sass/utilities/all';

.registration-page {
  .font-alt {
    font-size: 1.2em;
    @include touch {
      font-size: .9em; } }

  img.arrow-down {
    padding: 0 30px 0 10px;
    height: 32px;
    @include mobile {
      height: 21px; } }

  .box {
    box-sizing: border-box;

    &.selected {
      box-shadow: 0 0 2px 2px #3298dc; } }


  .event-details {
    height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; } }
