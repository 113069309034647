@import '../../node_modules/bulma/sass/utilities/all';
@import '../../node_modules/bulma/sass/elements/notification';

.login-page {
  .font-alt {
    font-size: 1.2em;
    @include touch {
      font-size: .9em; } }

  .level-item {
    @include tablet {
      max-width: 50%; }

    &.email-login {
      @include mobile {
        margin-top: 30px;
        padding-top: 10px;
        border-top: 1px solid #686868; }
      @include tablet {
        flex-shrink: 1;
        padding-left: 18px;
        border-left: 1px solid #686868; } }

    &.social-login {
      @include tablet {
        flex-shrink: 1;
        padding-right: 18px; } } } }



