@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap');

// Set your brand colors
$background-color: #fff;
$purple: #8a4d76;
$pink: #fa7c91;
$brown: #757763;
$black: #080808;
$white: #ffffff;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;

$scheme-main: $black;
$scheme-main-bis: #8d371b;
$scheme-invert: $white;
$text: $white;
$text-strong: $white; // title

// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif;
$grey-dark: rgba(255,255,255, .3);
$grey-light: $beige-light;
$primary: #953a1c;
$link: #859ce6;
$link-hover: $white;
$widescreen-enabled: true;
$fullhd-enabled: true;

// Update some of Bulma's component variables
$body-background-color: $black;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

$navbar-height: 4.25rem;
$navbar-item-img-max-height: 2.45rem;
$navbar-item-hover-color: $white;
$navbar-dropdown-arrow: $primary;
$navbar-dropdown-item-hover-background-color: $scheme-main-bis;
$navbar-dropdown-border-top: 2px solid $scheme-main;

$input-disabled-background-color: #000;
$button-text-hover-background-color: transparent;
$button-border-color: $grey-dark;
$button-border-width: 2px;

$modal-background-background-color: rgba($white, .3);
$modal-card-head-background-color: $black;

// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/all';
@import '../node_modules/bulma/sass/base/all';
@import '../node_modules/bulma/sass/helpers/typography';
@import '../node_modules/bulma/sass/helpers/spacing';
@import '../node_modules/bulma/sass/helpers/float';

@import '../node_modules/bulma/sass/form/all.sass';

@import '../node_modules/bulma/sass/components/navbar.sass';
@import '../node_modules/bulma/sass/components/level.sass';
@import '../node_modules/bulma/sass/components/dropdown.sass';
@import '../node_modules/bulma/sass/components/modal.sass';

@import '../node_modules/bulma/sass/elements/button.sass';
@import '../node_modules/bulma/sass/elements/container.sass';
@import '../node_modules/bulma/sass/elements/content.sass';
@import '../node_modules/bulma/sass/elements/title.sass';
@import '../node_modules/bulma/sass/elements/box.sass';
@import '../node_modules/bulma/sass/elements/tag.sass';
@import '../node_modules/bulma/sass/elements/other.sass';

@import '../node_modules/bulma/sass/layout/hero.sass';
@import '../node_modules/bulma/sass/layout/section.sass';

@import '../node_modules/bulma/sass/grid/columns.sass';
@import '../node_modules/bulma/sass/grid/tiles.sass';


//@import '../node_modules/bulma-pageloader/dist/css/bulma-pageloader'

html {
  height: 100%; }

body {
  min-height: 100%;
  //background: radial-gradient(circle, rgba(31,32,36,0.48082983193277307) 0%, rgba(8,8,9,0.8757878151260504) 100%),url("/background01.jpg")
  background: url("/grunge-dark.jpg"); }

.font-alt {
  font-family: 'Fredericka the Great', cursive;
  //text-shadow: 1px 1px 4px #a8a8a8
  color: #fff; }


button {
  a:visited, a:hover {
    color: inherit; }

  &.with-no-border {
    border: none; } }

.navbar {
  .navbar-menu.is-active {
    position: absolute;
    right: 0; }

  .navbar-link, .navbar-item {
    font-size: 1rem; }

  button {
    outline: none;
    background-color: transparent;
    border: none;
    &:focus, &:active, &:hover {
      color: inherit;
      border-radius: 0; } } }

button.dropdown-item, a.dropdown-item {
  background-color: transparent;
  border: none;
  &:hover {
    color: #1c1c1c; } }

@for $i from 1 through 100 {
  .min-height-#{$i*10} {
    min-height: #{$i*10}px; } }


.hero {
  &.is-fullpage {
    min-height: 100%;
    position: absolute;
    top: 0;
    width: 100%; } }

