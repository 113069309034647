

.pageloader {
  background-color: #2f2f2f;

  .title {
    font-weight: normal;
    font-size: 1em;
    color: inherit; } }

.new-item {
  .box {
    box-sizing: border-box;
    border: 6px solid #101010;
    cursor: pointer;
    background-color: #1c1c1c;
    color: #101010;
    display: flex;
    justify-content: center;
    align-items: center; }

  .box:hover {
    box-shadow: #2a2a2a 2px 6px 6px; } }


.tile.wrap {
  flex-wrap: wrap; }

.tile.is-child {
  &.selected {
    box-shadow: 0 0 2px 2px #3298dc; }

  p {
    cursor: default; } }

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.97); }




